import React from "react";
import { Link } from "gatsby";
import { useSelector } from "react-redux";
import { PrismicRichText } from "@prismicio/react";
import { Carousel } from "react-bootstrap";
import MOBILE_OIL_GOLD from "../../images/siteImages/home/MOBILE_OIL_GOLD.jpg";
import MAP_BANNER from "../../images/siteImages/home/MAP_BANNER.jpg";

const SliderImages = [MOBILE_OIL_GOLD, MAP_BANNER];

const CarouselTop = ({ slider = [] }) => {
  const language = useSelector((state) => state.language);
  let isRtl;
  language === "ar-ae" && (isRtl = true);
  language === "so-kr" && (isRtl = true);
  language === "fa-ir" && (isRtl = true);

  const getBannerClass = (position) => {
    console.log("Pos", position);
    switch (position) {
      case "Right":
        return "container slide-caption right-banner";
      case "Center":
        return "container slide-caption center-banner";
      case "Left":
      default:
        return "container slide-caption";
    }
  };

  return (
    <>
      <Carousel fade={true}>
        {slider.map((obj, index) => (
          <Carousel.Item key={index}>
            {!obj.slider_media.url && (
              <img
                className="d-block w-100"
                src={SliderImages[index]}
                alt="First slide"
              />
            )}
            {obj.slider_media.url && (
              <video width="100%" autoPlay="true" loop="true" muted>
                <source src={obj.slider_media.url} type="video/mp4" />
              </video>
            )}

            <Carousel.Caption>
              <div className={`${getBannerClass(obj.content_position)}`}>
                <div className="cta-content">
                  {ctaHeadingRender(obj)}
                  <PrismicRichText field={obj.cta_content.richText} />
                  {ctaButtonRender(obj, isRtl)}
                </div>
              </div>
            </Carousel.Caption>

            {/*  <div className="previos-button" style={{backgroundImage: `url(${index === 1 ? slider[index-1].slider_image.url:slider[slider.length-1].slider_image.url })`}}></div>
            <div className="next-button" style={{backgroundImage: `url(${index !== slider.length-1 ? slider[index+1].slider_image.url:slider.slider_image.url })`}}></div> */}
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
};

const ctaHeadingRender = (sliderObj) => {
  const headObj = sliderObj.cta_heading.text.split("<br>");
  return (
    <h3>
      {headObj.map((hText, index) => {
        let stxt = hText;
        stxt = stxt.replace(/<b>/, "~~");
        stxt = stxt.replace(/<\/b>/, "~~");
        const headText = stxt.split("~~");
        const hTxt = headText.map((txt, ii) => {
          const tag = ii + 1;
          if (headText.length === 1) {
            return <>{txt}</>;
          }
          if (tag % 2 === 0) {
            return <spans className="boldTxt">{txt}</spans>;
          }
          return <>{txt}</>;
        });
        if (headObj.length === 1) {
          return <>{hTxt}</>;
        }
        if (index < headObj.length - 1) {
          return (
            <>
              {hTxt} <br />
            </>
          );
        }
        return (
          <span key={index} className="highlight">
            {hTxt}{" "}
          </span>
        );
      })}
    </h3>
  );
};

const ctaButtonRender = (sliderObj, isRtl) => {
  if (sliderObj.open_blank) {
    return (
      <a
        href={sliderObj.cta_link.text}
        target="_blank"
        rel="noreferrer"
        className={isRtl ? "left" : "right"}
      >
        {sliderObj.cta_label.text}
      </a>
    );
  }
  return (
    <Link to={sliderObj.cta_link.text} className={isRtl ? "left" : "right"}>
      {sliderObj.cta_label.text}
    </Link>
  );
};

export default CarouselTop;
